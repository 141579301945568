/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { createRequestToDetachInvestor } from '../../redux/actions/investor/detachInvestor';
import { PAMMState } from '../../redux/reducers/rootReducer';
import { Investor } from '../../types/investorTypes';
import { Master } from '../../types/masterTypes';
import { setDisconnectFromMasterConfirmationStatus } from '../../redux/actions/setMobileMenusStatus';
import { spaceSeparatedThousands } from '../../utils/number';
import { getEquityOverInvestments } from '../../utils/value';
import getCurrencySymbol from '../../utils/currency';

const ConfirmDisconnectFromMasterMenu: React.FC<any> = ({
  menuHeight = 0,
  onSwipe,
  onCancel
}): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history: History = useHistory();
  const customColor = useSelector((state: PAMMState) => state.configReducer.customColor);

  const style = {
    width: '100%',
    height: menuHeight,
    position: 'fixed',
    zIndex: 9999,
    bottom: '-5rem',
    backgroundColor: 'white',
    transition: '0.5s',
    padding: '1.5rem',
    fontSize: '1.3rem'
  } as React.CSSProperties;

  const buttonStyle = {
    backgroundColor: customColor,
    color: 'white',
    border: 0,
    borderRadius: 10,
    fontSize: 13,
    paddingBottom: 7,
    WebkitBoxShadow: '0px 0px 7px 2px rgba(0,0,0,0.1)',
    boxShadow: '0px 0px 7px 2px rgba(0,0,0,0.1)',
    width: '70%'
  } as React.CSSProperties;

  const api: string = useSelector((state: PAMMState) => state.configReducer.api);
  const investor: Investor = useSelector((state: PAMMState) => state.investorReducer.investor);
  const master: Master = useSelector((state: PAMMState) => state.masterReducer.master);

  const currenciesConfig: Record<string, string> = useSelector((state: PAMMState) => state.configReducer.currency);
  const currency = getCurrencySymbol(investor?.Currency, currenciesConfig);


  const handleAttach = (): void => {
    dispatch(createRequestToDetachInvestor(api, investor.Id.toString(), master.Id.toString(), history));
    dispatch(setDisconnectFromMasterConfirmationStatus(false));
  };

  return (
    <div
      id="confirm-disconnect-from-master-menu"
      style={style}
      onPointerMove={(event) => onSwipe(event)}
      onPointerCancel={() => onCancel('confirmDisconnectFromMasterMenu')}
    >
      <h6 className="d-flex justify-content-center text-center">
        <FormattedMessage id="masterDetails.mobile.disconnectFromThisMaster" />?
      </h6>
      {(investor && investor?.AccountOptions?.InvestorOpenOrdersTab === 'Show') && (
        <div className="mb-3 mt-3 d-flex justify-content-center flex-wrap" style={{ fontSize: 14 }}>
          <span className="pr-3">
            {`${intl.formatMessage({id: 'investorDetails.balanceIndicators.equity'})}: ${currency}${spaceSeparatedThousands(investor?.EquityNoCredit)} (${getEquityOverInvestments(investor)} %)`}
          </span>
          {/* <span className={investor?.EquityNoCredit === investor?.DisconnectEquity ? 'pr-3' : 'text-danger pr-3'}>
            {`${intl.formatMessage({id: 'investorDetails.balanceIndicators.disconnectEquity'})}: ${currency}${spaceSeparatedThousands(investor?.DisconnectEquity)}`}
          </span> */}
        </div>
      )}
      <div className="d-flex justify-content-center mt-3">
        <Button style={buttonStyle} onClick={handleAttach}>
          <FormattedMessage id="masterDetails.mobile.disconnectFromThisMaster" />
        </Button>
      </div>

    </div>
  );
};

export default ConfirmDisconnectFromMasterMenu;
